import React from "react";

const testimonials = [
  {
    type: "Student Testimonial",
    quote:
      "Thanks to Grand Trust Ed, I got into my dream university in Egypt. The team was incredibly supportive throughout the entire process!",
    name: "Ahmed M.",
  },
  {
    type: "Case Study",
    story:
      "Sara struggled with her university applications and visa process. Grand Trust Ed provided personalized guidance and support, and now she is excelling in her chosen program at a top Egyptian university.",
    name: "Sara K.",
  },
  {
    type: "Parent Testimonial",
    quote:
      "As a parent, I was worried about sending my child abroad for studies. Grand Trust Ed made the process smooth and worry-free. They took care of everything!",
    name: "Mohamed R.",
  },
];

const CustomerReviews = () => {
  return (
    <section id="customer-reviews" className="py-20 bg-gray-100 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">
          Testimonials and Success Stories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-2xl">
              <h3 className="text-xl font-bold mb-2">{testimonial.type}</h3>
              {testimonial.quote ? (
                <p className="italic mb-4">"{testimonial.quote}"</p>
              ) : (
                <p className="mb-4">{testimonial.story}</p>
              )}
              <p className="font-semibold">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
