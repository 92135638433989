import React from "react";

const resources = [
  {
    category: "Application Tips",
    items: [
      {
        title: "How to Write a Personal Statement",
        description:
          "A step-by-step guide to writing a compelling personal statement for your university application.",
        link: "#",
      },
      {
        title: "Preparing for University Interviews",
        description:
          "Tips and strategies to help you prepare for university interviews and make a great impression.",
        link: "#",
      },
    ],
  },
  {
    category: "Document Checklists",
    items: [
      {
        title: "University Application Checklist",
        description:
          "Downloadable checklist of all the documents required for university applications.",
        link: "#",
      },
      {
        title: "Visa Application Checklist",
        description:
          "Comprehensive checklist of all the documents needed for visa applications.",
        link: "#",
      },
    ],
  },
  {
    category: "Educational System Guides",
    items: [
      {
        title: "Understanding the Egyptian Educational System",
        description:
          "An overview of the Egyptian educational system and how to navigate it.",
        link: "#",
      },
      {
        title: "Comparing Educational Systems Worldwide",
        description:
          "A comparative guide to educational systems around the world.",
        link: "#",
      },
    ],
  },
];

const ResourcesAndGuides = () => {
  return (
    <section id="resources-and-guides" className="py-20 text-center bg-white">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">Resources and Guides</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {resources.map((resource, index) => (
            <div key={index} className="bg-gray-100 p-6 rounded-xl shadow-2xl">
              <h3 className="text-xl font-bold mb-4">{resource.category}</h3>
              {resource.items.map((item, idx) => (
                <div key={idx} className="mb-4">
                  <h4 className="text-lg font-semibold">{item.title}</h4>
                  <p className="mb-2">{item.description}</p>
                  <a href={item.link} className="text-blue-500 underline">
                    Read more
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResourcesAndGuides;
