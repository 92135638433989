import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { Link } from "react-scroll";
import gtedVideo from "../assets/gted.mp4";

const AboutUs = () => {
  const { t, i18n } = useTranslation(); // Initialize the translation hook
  const fontSize =
    i18n.language === "ar" ? "md:text-xl text-lg" : "md:text-lg text-base";

  return (
    <section id="about-us" className="py-10 bg-gray-100 text-center">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-20">
        <div className="w-full lg:w-1/3">
          <video src={gtedVideo} autoPlay muted controls></video>
        </div>

        <div className=" w-full lg:w-2/3 rounded-md shadow-2xl p-10">
          <h2 className="text-3xl font-semibold mb-4">{t("aboutUs.title")}</h2>
          <p className={` mb-6`}>{t("aboutUs.description1")}</p>
          <p className={` mb-6`}>{t("aboutUs.description2")}</p>
          <Link
            to="contact-us"
            spy={true}
            smooth={true}
            duration={500}
            className="bg-blue-500 text-white px-4 py-2 rounded inline-block cursor-pointer"
          >
            {t("aboutUs.contactUsButton")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
