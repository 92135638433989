import React from "react";

const news = [
  {
    category: "Industry News",
    items: [
      {
        title: "Changes in Higher Education Policies",
        date: "June 15, 2024",
        description:
          "New policies affecting university admissions and student loans have been announced. These changes will impact the application process starting next year.",
        link: "#",
      },
      {
        title: "University Application Deadlines",
        date: "June 10, 2024",
        description:
          "The deadlines for university applications have been updated. Make sure to check the new dates and submit your applications on time.",
        link: "#",
      },
    ],
  },
  {
    category: "Company Announcements",
    items: [
      {
        title: "New Services Launched",
        date: "May 25, 2024",
        description:
          "We are excited to announce new services aimed at helping students with scholarship applications and job placements.",
        link: "#",
      },
      {
        title: "Partnership with XYZ University",
        date: "May 20, 2024",
        description:
          "We have partnered with XYZ University to provide exclusive benefits and support to our students. Stay tuned for more details on upcoming events and programs.",
        link: "#",
      },
    ],
  },
];

const NewsAndUpdates = () => {
  return (
    <section id="news-and-updates" className="py-20 text-center bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">News and Updates</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
          {news.map((section, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-2xl">
              <h3 className="text-xl font-bold mb-4">{section.category}</h3>
              {section.items.map((item, idx) => (
                <div key={idx} className="mb-4">
                  <h4 className="text-lg font-semibold">{item.title}</h4>
                  <p className="text-sm text-gray-600 mb-2">{item.date}</p>
                  <p className="mb-2">{item.description}</p>
                  <a href={item.link} className="text-blue-500 underline">
                    Read more
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsAndUpdates;
