import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaChalkboardTeacher,
  FaInfoCircle,
  FaUniversity,
  FaUserGraduate,
} from "react-icons/fa";

const services = [
  {
    icon: <FaUniversity className="text-6xl text-blue-500 mb-4" />,
    title: "ourServices.service1Title",
    description: "ourServices.service1Description",
    details: [
      "ourServices.service1Details1",
      "ourServices.service1Details2",
      "ourServices.service1Details3",
    ],
    // pricing: "ourServices.service1Pricing",
  },
  {
    icon: <FaChalkboardTeacher className="text-6xl text-green-500 mb-4" />,
    title: "ourServices.service2Title",
    description: "ourServices.service2Description",
    details: [
      "ourServices.service2Details1",
      "ourServices.service2Details2",
      "ourServices.service2Details3",
    ],
    // pricing: "ourServices.service2Pricing",
  },
  {
    icon: <FaUserGraduate className="text-6xl text-yellow-500 mb-4" />,
    title: "ourServices.service3Title",
    description: "ourServices.service3Description",
    details: [
      "ourServices.service3Details1",
      "ourServices.service3Details2",
      "ourServices.service3Details3",
    ],
    // pricing: "ourServices.service3Pricing",
  },
  {
    icon: <FaInfoCircle className="text-6xl text-red-500 mb-4" />,
    title: "ourServices.service4Title",
    description: "ourServices.service4Description",
    details: [
      "ourServices.service4Details1",
      "ourServices.service4Details2",
      "ourServices.service4Details3",
    ],
    // pricing: "ourServices.service4Pricing",
  },
];

const OurServices = () => {
  const { t, i18n } = useTranslation(); // Initialize the translation hook

  return (
    <section id="our-services" className="py-20 text-center bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">
          {t("ourServices.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white flex flex-col justify-center items-center py-6 rounded-xl shadow-2xl p-6"
            >
              {service.icon}
              <h3 className="text-xl font-bold mb-2">{t(service.title)}</h3>
              <p className="mb-4">{t(service.description)}</p>
              <ul className="text-left mb-4">
                {service.details.map((detail, idx) => (
                  <li key={idx} className="list-disc list-inside mb-2">
                    {t(detail)}
                  </li>
                ))}
              </ul>
              <p className="font-bold text-lg">{t(service.pricing)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const HowItWorks = () => {
  const { t, i18n } = useTranslation(); // Initialize the translation hook
  const textAlign =
    i18n.language === "ar"
      ? "md:text-right text-center"
      : "md:text-left text-center";

  return (
    <section id="how-it-works" className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold mb-12">
          {t("howItWorks.title")}
        </h2>
        <div className={`${textAlign} max-w-4xl mx-auto`}>
          <h3 className="text-2xl font-bold mb-4">
            {t("howItWorks.processOverview")}
          </h3>
          <h3 className="text-2xl font-bold mb-4">
            {t("howItWorks.timelineTitle")}
          </h3>
          <p className="mb-6">
            {t("howItWorks.timelineApplicationProcessing")}
          </p>
          <p className="mb-6">{t("howItWorks.timelineDocumentVerification")}</p>
          <h3 className="text-2xl font-bold mb-4">{t("faq.title")}</h3>
          <p className="mb-6">
            <strong>{t("faq.question1")}</strong>
            <br />
            <strong>{t("faq.answer1")}</strong>
          </p>
          <p className="mb-6">
            <strong>{t("faq.question2")}</strong>
            <br />
            <strong>{t("faq.answer2")}</strong>
          </p>
          <p className="mb-6">
            <strong>{t("faq.question3")}</strong>
            <br />
            <strong>{t("faq.answer3")}</strong>
          </p>
        </div>
      </div>
    </section>
  );
};

const App = () => {
  return (
    <div>
      <OurServices />
      <HowItWorks />
    </div>
  );
};

export default App;
