import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: {
      aboutUs: {
        title: "About Us",
        description1:
          "Grand Trust Ed is an Egyptian company dedicated to helping students apply to Egyptian universities and providing all necessary information regarding studying in Egypt. We understand the challenges that students face when navigating the complex application process and aim to simplify it for them. Our team of experts is committed to providing personalized support to each student, ensuring they have a smooth and successful experience.",
        description2:
          "Whether it's assistance with university applications, educational counseling, visa support, or answering student inquiries, we are here to help every step of the way. Our mission is to empower students to achieve their academic goals and make their dreams of studying in Egypt a reality.",
        contactUsButton: "Contact Us",
        imageAlt: "About Us",
      },
      header: {
        aboutUs: "About Us",
        ourServices: "Our Services",
        customerReviews: "Customer Reviews",
        contactUs: "Contact Us",
      },
      ourServices: {
        title: "Our Services",

        service1Title: "University Application Assistance",
        service1Description:
          "Help with completing and submitting university applications.",
        service1Details1: "Guidance on selecting the right universities.",
        service1Details2: "Assistance with application forms and essays.",
        service1Details3: "Submission tracking and follow-up.",
        service1Pricing: "$500 - $1000",

        service2Title: "Educational Counseling",
        service2Description:
          "Personalized advice on choosing universities and programs.",

        service2Details1: "One-on-one counseling sessions.",
        service2Details2: "Career assessment and planning.",
        service2Details3: "Support with course selection.",
        service2Pricing: "$300 - $700",

        service3Title: "Visa Assistance",
        service3Description:
          "Support with the visa application process for international students.",

        service3Details1: "Preparation of required documents.",
        service3Details2: "Guidance on visa interviews.",
        service3Details3: "Application tracking and updates.",

        service3Pricing: "$200 - $500",

        service4Title: "Student Inquiries",
        service4Description:
          "Providing detailed information about studying in Egypt and available programs.",

        service4Details1: "Answering questions about university programs.",
        service4Details2: "Providing information on living in Egypt.",
        service4Details3: "Support with accommodation and logistics.",

        service4Pricing: "Free",
      },

      howItWorks: {
        title: "How It Works",
        processOverview:
          "Our services are designed to be straightforward and effective. We start with an initial consultation to understand your needs, followed by personalized assistance tailored to help you achieve your academic goals.",

        timelineTitle: "Timeline",
        timelineApplicationProcessing: "Application processing time: 2-4 weeks",
        timelineDocumentVerification: "Document verification period: 1-2 weeks",
      },
      faq: {
        title: "FAQ",
        question1: "How do I get started?",
        answer1:
          "You can start by contacting us through our website or visiting our office for an initial consultation.",
        question2: "What documents do I need?",
        answer2:
          "Required documents vary based on the service, but generally include academic transcripts, identification, and proof of residency.",
        question3: "How much does it cost?",
        answer3:
          "Pricing varies by service. Please refer to the service descriptions above for detailed pricing information.",
      },
    },
  },
  ar: {
    translation: {
      aboutUs: {
        title: "معلومات عنا",
        description1:
          "Grand Trust Ed هي شركة مصرية مكرسة لمساعدة الطلاب في التقديم للجامعات المصرية وتوفير كل المعلومات اللازمة بشأن الدراسة في مصر. نحن نفهم التحديات التي يواجهها الطلاب عند التنقل في عملية التقديم المعقدة ونهدف إلى تبسيطها لهم. فريقنا من الخبراء ملتزم بتقديم دعم شخصي لكل طالب، لضمان أن تكون تجربتهم سلسة وناجحة.",
        description2:
          "سواء كان ذلك المساعدة في تقديم الطلبات الجامعية أو الاستشارات التعليمية أو دعم التأشيرات أو الرد على استفسارات الطلاب، فنحن هنا لمساعدتك في كل خطوة على الطريق. مهمتنا هي تمكين الطلاب من تحقيق أهدافهم الأكاديمية وتحقيق أحلامهم في الدراسة في مصر.",
        contactUsButton: "اتصل بنا",
        imageAlt: "معلومات عنا",
      },
      header: {
        aboutUs: "معلومات عنا",
        ourServices: "خدماتنا",
        customerReviews: "آراء العملاء",
        contactUs: "اتصل بنا",
      },
      ourServices: {
        title: "خدماتنا",

        service1Title: "مساعدة في تقديم الطلبات الجامعية",
        service1Description: "مساعدة في استكمال وتقديم الطلبات الجامعية.",
        service1Details1: "توجيه حول اختيار الجامعات المناسبة.",
        service1Details2: "مساعدة في استكمال نماذج الطلبات والمقالات.",
        service1Details3: "متابعة تقديم الطلبات والمتابعة.",
        service1Pricing: "$500 - $1000",

        service2Title: "استشارات تعليمية",
        service2Description: "نصائح مخصصة حول اختيار الجامعات والبرامج.",

        service2Details1: "جلسات استشارية فردية.",
        service2Details2: "تقييم وتخطيط المهنة.",
        service2Details3: "دعم في اختيار الدورات.",

        service2Pricing: "$300 - $700",

        service3Title: "مساعدة في التأشيرات",

        service3Description:
          "دعم في عملية تقديم الطلبات للحصول على التأشيرات للطلاب الدوليين.",

        service3Details1: "إعداد الوثائق المطلوبة.",
        service3Details2: "توجيه حول مقابلات التأشيرة.",
        service3Details3: "متابعة الطلبات والتحديثات.",

        service3Pricing: "$200 - $500",

        service4Title: "استفسارات الطلاب",
        service4Description:
          "توفير معلومات مفصلة حول الدراسة في مصر والبرامج المتاحة.",

        service4Details1: "الإجابة على الأسئلة حول برامج الجامعات.",
        service4Details2: "توفير معلومات عن الحياة في مصر.",
        service4Details3: "دعم في الإقامة واللوجستيات.",

        service4Pricing: "مجاني",
      },
      howItWorks: {
        title: "كيف تعمل خدماتنا",
        processOverview:
          "تم تصميم خدماتنا لتكون بسيطة وفعالة. نبدأ باستشارة أولية لفهم احتياجاتك، تليها مساعدة مخصصة مصممة لمساعدتك في تحقيق أهدافك الأكاديمية.",

        timelineTitle: "الجدول الزمني",
        timelineApplicationProcessing: "مدة معالجة الطلب: 2-4 أسابيع",
        timelineDocumentVerification: "مدة التحقق من الوثائق: 1-2 أسابيع",
      },
      faq: {
        title: "الأسئلة المتكررة",
        question1: "كيف أبدأ؟",
        answer1:
          "يمكنك البدء من خلال الاتصال بنا عبر موقعنا الإلكتروني أو زيارة مكتبنا لإجراء استشارة أولية.",
        question2: "ما الوثائق التي أحتاجها؟",
        answer2:
          "تختلف الوثائق المطلوبة بناءً على الخدمة، ولكنها تشمل بشكل عام السجلات الأكاديمية، الهوية، وإثبات الإقامة.",
        question3: "كم يكلف ذلك؟",
        answer3:
          "تختلف الأسعار حسب الخدمة. يرجى الرجوع إلى أوصاف الخدمات أعلاه لمزيد من المعلومات التفصيلية حول الأسعار.",
      },
    },
  },
};

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

export default i18n;
